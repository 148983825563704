<template>
  <view-item title="营销活动">
    <template #operation>
      <en-button v-if="tabs.active === 'order'" type="primary" @click="operation.purchase.click">套餐购买</en-button>
      <en-button v-if="tabs.active === 'group'" type="primary" @click="operation.group.click">新增活动</en-button>
      <en-button v-if="tabs.active === 'meal'" type="primary" @click="operation.groupCombo.click">新增套餐卡</en-button>
      <en-button v-if="tabs.active === 'order'">导出</en-button>
      <en-dropdown v-if="tabs.active === 'group'" @command="operation.options.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="download">下载导入模版</en-dropdown-item>
          <en-dropdown-item command="upload">导入活动</en-dropdown-item>
          <en-dropdown-item command="export">导出活动</en-dropdown-item>
        </template>
      </en-dropdown>
      <button-ajax v-if="tabs.active === 'mallagency'" type="primary" :method="operation.bind.click">微信绑定</button-ajax>
      <button-ajax v-if="tabs.active === 'mallagency'" :method="operation.save.click">保存</button-ajax>
    </template>

    <en-card class="h-full" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="营销订单" name="order">
              <table-dynamic
                code="MALCOFD"
                :data="order.data"
                :loading="order.loading"
                :height="height - 55"
                pagination
                :paging="order.paging"
                :method="order.get"
                :config="order.config"
              >
                <template #OPERATION="{ row }: { row: EnocloudMallDefinitions['MallComboOrderQueryDto'] }">
                  <en-button v-if="row.status?.code === 'WR'" type="primary" text @click="order.operation.refund.click(row)">退款</en-button>
                  <en-button
                    v-if="row.orderClosedManually?.value && (row.status?.code === 'PD' || row.status?.code === 'F')"
                    type="primary"
                    link
                    @click="order.operation.receiving.click(row)"
                  >
                    查看收货人信息
                  </en-button>
                  <en-button
                    v-if="row.orderClosedManually?.value && (row.status?.code === 'PD' || row.status?.code === 'F')"
                    type="primary"
                    link
                    @click="order.operation.logistics.click(row)"
                  >
                    {{ row.status?.code === 'F' ? '' : '上传' }}物流信息
                  </en-button>
                  <en-button
                    v-if="row.orderClosedManually?.value && (row.status?.code === 'PD' || row.status?.code === 'F')"
                    type="primary"
                    link
                    @click="order.operation.invoice.click(row)"
                  >
                    {{ row.status?.code === 'F' ? '' : '上传' }}开票信息
                  </en-button>
                </template>

                <template #SERIAL_NO="{ row }: { row: EnocloudMallDefinitions['MallComboOrderQueryDto'] }">
                  <en-button type="primary" link @click="order.serialNo.click(row)">
                    {{ row.serialNo }}
                  </en-button>
                </template>
              </table-dynamic>
            </en-tab-pane>

            <en-tab-pane label="活动设置" name="group">
              <en-table :data="group.data" :height="height - 55" :method="group.get" :paging="group.paging" :loading="group.loading" pagination>
                <en-table-column label="操作" width="220">
                  <template #default="{ row }: { row: EnocloudMallDefinitions['MallGroupPurchaseDto'] }">
                    <en-button
                      v-if="row.status?.code && ['I', 'E'].includes(row.status.code)"
                      type="primary"
                      link
                      @click="group.operation.count.click(row)"
                    >
                      统计
                    </en-button>
                    <en-button
                      v-if="row.status?.code && ['I', 'E'].includes(row.status.code)"
                      type="primary"
                      link
                      @click="group.operation.summary.click(row)"
                    >
                      贡献度分析
                    </en-button>
                    <en-button v-if="row.profitSharingCount > 0" type="primary" link @click="group.operation.profitsharing.click(row)">
                      分账记录
                    </en-button>
                  </template>
                </en-table-column>
                <en-table-column label="活动编号" prop="serialNo" width="150">
                  <template #default="{ row }: { row: EnocloudMallDefinitions['MallGroupPurchaseDto'] }">
                    <en-button type="primary" text @click="group.serialNo.click(row)">{{ row.serialNo }}</en-button>
                  </template>
                </en-table-column>
                <en-table-column label="活动名称" prop="name"></en-table-column>
                <en-table-column label="活动期限">
                  <template #default="{ row }: { row: EnocloudMallDefinitions['MallGroupPurchaseDto'] }">{{
                    `${row.startDate}至${row.endDate}`
                  }}</template>
                </en-table-column>
                <en-table-column label="创建人" prop="preparedBy.name"></en-table-column>
                <en-table-column label="经手人" prop="auditBy.name"></en-table-column>
                <en-table-column label="创建日期" prop="preparedDatetime">
                  <template #default="{ row }: { row: EnocloudMallDefinitions['MallGroupPurchaseDto'] }">
                    {{ formatDate(row.preparedDatetime) }}
                  </template>
                </en-table-column>
                <en-table-column label="状态" prop="status.message"></en-table-column>
                <en-table-column label="备注" prop="content"></en-table-column>
              </en-table>
            </en-tab-pane>
            <en-tab-pane label="套餐设置" name="meal">
              <en-table :data="meal.data" :height="height - 55" :method="meal.get" :paging="meal.paging" :loading="meal.loading" pagination>
                <en-table-column label="套餐编号" prop="mallGroupPurchase.serialNo" width="150">
                  <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboDto'] }">
                    <en-button type="primary" text @click="meal.serialNo.click(row)">{{ row.mallGroupPurchase?.serialNo }}</en-button>
                  </template>
                </en-table-column>
                <en-table-column label="套餐名称" prop="name" width="150"> </en-table-column>

                <en-table-column label="套餐期限">
                  <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboDto'] }">{{
                    `${row.mallGroupPurchase.startDate}至${row.mallGroupPurchase.endDate}`
                  }}</template>
                </en-table-column>
                <en-table-column label="创建人" prop="mallGroupPurchase.preparedBy.name"></en-table-column>
                <en-table-column label="经手人" prop="mallGroupPurchase.auditBy.name"></en-table-column>
                <en-table-column label="创建日期" prop="preparedDatetime">
                  <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboDto'] }">
                    {{ formatDate(row.preparedDatetime) }}
                  </template>
                </en-table-column>
                <en-table-column label="状态" prop="mallGroupPurchase.status.message"></en-table-column>
                <en-table-column label="备注" prop="comment"></en-table-column>
              </en-table>
            </en-tab-pane>

            <en-tab-pane label="代理人设置" name="mallagency">
              <en-table
                :data="mallagency.data"
                :height="height - 55"
                :method="mallagency.get"
                :paging="mallagency.paging"
                :loading="mallagency.loading"
                pagination
              >
                <en-table-column label="操作" width="150">
                  <template #default="{ row }: { row: MallAgencyDto }">
                    <en-button type="primary" text @click="mallagency.operation.edit.click(row)">编辑</en-button>
                    <button-delete :method="mallagency.operation.delete.click" :params="row">删除</button-delete>
                  </template>
                </en-table-column>
                <en-table-column label="代理人名称">
                  <template #default="{ row }: { row: MallAgencyDto }">
                    <en-input v-if="mallagency.currentRow?.id === row.id" v-model="row.name"></en-input>
                    <span v-else>{{ row.name }}</span>
                  </template>
                </en-table-column>
                <en-table-column label="类别">
                  <template #default="{ row }: { row: MallAgencyDto }">
                    <select-maintain
                      v-if="mallagency.currentRow?.id === row.id"
                      v-model="row.type"
                      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['MAGCTYPE']) }"
                      :props="{ label: 'message', value: '' }"
                      value-key="code"
                    ></select-maintain>
                    <span v-else>{{ row.type?.message }}</span>
                  </template>
                </en-table-column>
              </en-table>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>

  <mall-order-detail v-model="orderDetail.visible" :data="orderDetail.data" @confirm="order.get"></mall-order-detail>

  <mall-order-purchase v-model="purchase.visible" @confirm="order.get"></mall-order-purchase>

  <mall-order-logistics v-model="logistics.visible" :data="logistics.data" @confirm="order.get"></mall-order-logistics>

  <mall-order-receiving v-model="receiving.visible" :data="receiving.data"></mall-order-receiving>

  <mall-order-invoice v-model="invoice.visible" :data="invoice.data" @confirm="order.get"></mall-order-invoice>

  <mall-group-detail v-model="groupDetail.visible" :data="groupDetail.data"></mall-group-detail>

  <mall-group-combo v-model="groupComboDetail.visible" :data="groupComboDetail.data"></mall-group-combo>

  <en-upload :ref="setRef('uploadRef')" action="/enocloud/mall/combo/import" :on-confirm="() => group.get()" class="absolute"></en-upload>
</template>

<script lang="ts">
import { h } from 'vue'
import { EnMessageBox } from '@enocloud/components'
import { MallOrderPurchase } from '@enocloud/business'

import MallOrderDetail from '@client/components/mall-order-detail.vue'
import MallOrderLogistics from '@client/components/mall-order-logistics.vue'
import MallOrderReceiving from '@client/components/mall-order-receiving.vue'
import MallOrderInvoice from '@client/components/mall-order-invoice.vue'
import MallGroupDetail from '@client/components/mall-group-detail.vue'
import MallGroupCombo from '@client/components/mall-group-combo.vue'

type MallAgencyDto = EnocloudCommonDefinitions['MallAgencyDto']

export default factory({
  components: { MallOrderDetail, MallOrderPurchase, MallOrderLogistics, MallOrderReceiving, MallOrderInvoice, MallGroupDetail, MallGroupCombo },

  config: {
    children: {
      operation: {
        children: {
          bind: {
            async click() {
              const res = await this.ajax('GET /enocloud/common/mallagency/wechat/bind/url')
              return EnMessageBox({
                title: '微信绑定',
                center: true,
                showConfirmButton: false,
                message: h('img', { src: res.data[0], width: 300, height: 300, class: 'm-auto' })
              })
            }
          },
          options: {
            command(option: string) {
              switch (option) {
                case 'download':
                  window.open(`/enocloud/mall/combo/download`)
                  break
                case 'upload':
                  this.refs.uploadRef.$el.querySelector('input').click()
                  break
                case 'export':
                  this.ajax('GET /enocloud/mall/combo/export', this.group.$ajaxParams)
                  break
              }
            }
          },
          purchase: {
            click() {
              this.purchase.visible = true
            }
          },
          group: {
            click() {
              this.groupDetail.data = null
              this.groupDetail.visible = true
            }
          },
          groupCombo: {
            click() {
              this.groupComboDetail.data = null
              this.groupComboDetail.visible = true
            }
          },
          save: {
            async click() {
              await this.mallagency.submit()
              return this.mallagency.get().then(() => (this.mallagency.currentRow = null))
            }
          }
        }
      },
      tabs: {
        active: 'order'
      },
      order: {
        ajax: {
          get: {
            action: 'GET /enocloud/mall/combo/order/query',
            summary: 'GET /enocloud/mall/combo/order/summary',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        config: {
          STATUS: {
            header: {
              filter: {
                field: 'status',
                type: 'select',
                props: {
                  ajax: { action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['MALCBODSTAT']) },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          }
        },
        children: {
          operation: {
            refund: {
              click(row: EnocloudMallDefinitions['MallComboOrderQueryDto']) {}
            },
            receiving: {
              click(row: EnocloudMallDefinitions['MallComboOrderQueryDto']) {
                this.receiving.data = Object.assign({}, row) as unknown as EnocloudMallDefinitions['MallComboOrderDto']
                this.receiving.visible = true
              }
            },
            logistics: {
              click(row: EnocloudMallDefinitions['MallComboOrderQueryDto']) {
                this.logistics.data = Object.assign({}, row) as unknown as EnocloudMallDefinitions['MallComboOrderDto']
                this.logistics.visible = true
              }
            },
            invoice: {
              click(row: EnocloudMallDefinitions['MallComboOrderQueryDto']) {
                this.invoice.data = Object.assign({}, row) as unknown as EnocloudMallDefinitions['MallComboOrderDto']
                this.invoice.visible = true
              }
            }
          },
          serialNo: {
            click(row: EnocloudMallDefinitions['MallComboOrderQueryDto']) {
              this.orderDetail.data = Object.assign({}, row) as unknown as EnocloudMallDefinitions['MallComboOrderDto']
              this.orderDetail.visible = true
            }
          }
        }
      },
      group: {
        ajax: {
          get: {
            action: 'GET /enocloud/mall/group/purchase',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        children: {
          operation: {
            count: { click(row: EnocloudMallDefinitions['MallGroupPurchaseDto']) {} },
            summary: { click(row: EnocloudMallDefinitions['MallGroupPurchaseDto']) {} },
            profitsharing: { click(row: EnocloudMallDefinitions['MallGroupPurchaseDto']) {} }
          },
          serialNo: {
            click(row: EnocloudMallDefinitions['MallGroupPurchaseDto']) {
              this.groupDetail.data = Object.assign({}, row)
              this.groupDetail.visible = true
            }
          }
        }
      },
      meal: {
        form: {
          data: {
            name: '',
            statusCode: 'P'
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/mall/combo',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.meal.form.data
            }
          }
        },
        children: {
          serialNo: {
            click(row: EnocloudMallDefinitions['MallComboDto']) {
              this.groupComboDetail.data = Object.assign({}, row)
              this.groupComboDetail.visible = true
            }
          }
        }
      },
      mallagency: {
        currentRow: null as MallAgencyDto | null,
        ajax: {
          get: {
            action: 'GET /enocloud/common/mallagency',
            data: 'array',
            loading: true,
            pagination: true
          },
          submit: {
            action: 'PUT /enocloud/common/mallagency',
            params(params) {
              params.data = this.mallagency.data
            }
          },
          delete: {
            action: 'DELETE /enocloud/common/mallagency/:agencyId'
          }
        },
        children: {
          operation: {
            edit: {
              click(row: MallAgencyDto) {
                this.mallagency.currentRow = row
              }
            },
            delete: {
              async click(row: MallAgencyDto) {
                await this.mallagency.delete({ paths: [row.id] })
                return this.mallagency.get()
              }
            }
          }
        }
      },
      logistics: {
        visible: false,
        data: null as EnocloudMallDefinitions['MallComboOrderDto'] | null
      },
      receiving: {
        visible: false,
        data: null as EnocloudMallDefinitions['MallComboOrderDto'] | null
      },
      invoice: {
        visible: false,
        data: null as EnocloudMallDefinitions['MallComboOrderDto'] | null
      },
      orderDetail: {
        visible: false,
        data: null as EnocloudMallDefinitions['MallComboOrderDto'] | null
      },
      purchase: {
        visible: false
      },
      groupDetail: {
        visible: false,
        data: null as EnocloudMallDefinitions['MallGroupPurchaseDto'] | null
      },
      groupComboDetail: {
        visible: false,
        data: null as EnocloudMallDefinitions['MallComboDto'] | null
      }
    }
  },

  mounted() {
    this.order.get()
    this.group.get()
    this.mallagency.get()
    this.meal.get()
  }
})
</script>
